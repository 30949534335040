import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageHeader from "../common/PageHeader"

export default function SinglePost({ data }) {
  const { title, content, featuredImage, date } = data.wpPost
  const img = featuredImage?.node.localFile.childImageSharp
  return (
    <div className="px-4 lg:px-8 space-y-4 lg:space-y-8 py-4 lg:py-16 max-w-4xl mx-auto h-full">
      <PageHeader text={title} />
      <div className="w-full space-y-4">
        <div className="">{/* <p className="date">{date}</p> */}</div>
        {/* {featuredImage && (
          <div className="w-full">
            <GatsbyImage image={getImage(img)} className="w-full h-full" />
          </div>
        )} */}
        <div>
          <div
            className="wp-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>{" "}
      </div>
    </div>
    //  <div className="h-full bg-gray-100 ">
    //     <div className="px-4 lg:px-8 space-y-4 lg:space-y-8 py-4 lg:py-8 max-w-4xl mx-auto h-full">
    //       <header className="space-y-4">
    //         <PageHeader text={title} />
    //         <div className="text-base">{date}</div>
    //       </header>
    //       <div className="flex flex-col lg:flex-row gap-8 lg:gap-16">
    //         {/* Primary column */}
    //         <div className="flex-1 overflow-hidden space-y-8">
    //           <div>
    //             <div
    //               className="wp-content"
    //               dangerouslySetInnerHTML={{ __html: content }}
    //             />
    //           </div>{" "}
    //           {featuredImage && <FeaturedImage image={featuredImage} />}
    //         </div>
    //         {/* Seconday column */}
    //         <div className="flex-1 max-w-xl"></div>
    //       </div>
    //     </div>
    //   </div>
  )
}
