import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import SinglePost from "../components/news/SinglePost"

export default function PostTemplate({ data }) {
  return (
    <div className="">
      <Seo title={data.wpPost.title} />

      <Layout>
        <SinglePost data={data} />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      date(formatString: "YYYY.MM.DD")
      databaseId

      featuredImage {
        node {
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                aspectRatio: 1.6
                quality: 70
              )
            }
          }
        }
      }
    }
  }
`
